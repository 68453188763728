import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Loader from "../../core/loader/loader";
import DataTransferService from "../../core/service/dataTransferService";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";

const ViewBranches = () => {
  const [branchesData, setBranchesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingCreation, setLoadingCreation] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const dataTransferService = new DataTransferService();
  const [profile, setProfile] = useState("");
  const userService = new UserService();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBranchesFromLocalStorage();
  }, []);

  const fetchBranchesFromLocalStorage = () => {
    setLoadingSearch(true);
    const organisationDTO = localStorage.getItem("organisationDTO");

    if (organisationDTO) {
      try {
        const organisation = JSON.parse(organisationDTO);
        if (organisation.branchDTOList) {
          setBranchesData(organisation.branchDTOList);
        } else {
          console.warn("No branchDTOList found in organisationDTO");
          setBranchesData([]);
        }
      } catch (error) {
        console.error(
          "Error parsing organisationDTO from localStorage:",
          error
        );
        setBranchesData([]);
      }
    } else {
      console.warn("No organisationDTO found in localStorage");
      setBranchesData([]);
    }

    setLoadingSearch(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleProceed = () => {
    navigate("/create-branches");
  };

  const filteredBranches = branchesData.filter(
    (branch) =>
      branch.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branch.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await dataTransferService.getOrgProfileImage();
        setProfile(userProfile || "");
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, []);

  const handleOrganisationCreation = () => {
    const organisationDTO = localStorage.getItem("organisationDTO");
    setLoadingCreation(true);
    userService
      .createOrganisation(organisationDTO)
      .then((response) => {
        if (response.data.responseCode === 201) {
          navigate("/invite-users");
        }
      })
      .catch((error) => {
        setLoadingCreation(false);
        handleErrorResponse("admin-create-organisation", error.response);
      });
  };

  return (
    <div className="d-flex align-items-center justify-content-center full-height">
      {(loadingCreation || loadingSearch) && (
        <div className="full-screen-loader">
          <Loader loading={loadingCreation || loadingSearch} />
        </div>
      )}

      <div
        className="view-branches-container p-4 d-flex flex-column"
        style={{ height: "100%" }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <p className="fs-22 fw-600">Hi {dataTransferService.getUserName()}</p>
          <img
            src={dataTransferService.getBloburl() + profile}
            alt="user-profile-icon"
            className="rounded-circle me-3"
            width={75}
            height={75}
          />
        </div>
        <hr />
        <div className="pt-5 d-flex align-items-center justify-content-between">
          <p className="fs-4 fw-600">
            {dataTransferService.getOrgName() || "Organisation Name"}
          </p>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline ms-4"
            onClick={handleProceed}
          >
            + Add location
          </button>
        </div>

        <div className="pt-40">
          <p className="pt-3 fs-6 fw-600">Your locations</p>
        </div>

        <div className="pt-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by location, name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className="input-group-text bg-white border-left-0">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            {searchTerm && (
              <span
                className="input-group-text bg-white border-left-0 pointer"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </div>
        </div>

        {loadingSearch ? (
          <Loader loading={loadingSearch} />
        ) : filteredBranches.length > 0 ? (
          filteredBranches.map((branch, index) => (
            <div
              key={index}
              className="pt-32 d-flex align-items-center justify-content-center"
            >
              <img
                src={
                  dataTransferService.getBloburl() +
                  dataTransferService.getOrgProfileImage()
                }
                alt="organisation-logo"
                width={80}
                height={80}
              />
              <div className="d-flex flex-column pe-67 ps-4 w-100">
                <span className="fs-4 fw-600">{branch.branchName}</span>
                <span className="fs-15 fw-500">{branch.branchAddress}</span>
                <span className="fs-14 fw-light">
                  Ph: {branch.branchMobileNumber}
                </span>
              </div>
              <hr />
            </div>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center mt-5">
            No results found
          </p>
        )}

        <div className="mt-5 d-flex align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-success w-25"
            onClick={handleOrganisationCreation}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewBranches;
