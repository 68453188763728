import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    color: '#343a40',
    position: 'relative',
    overflow: 'hidden',
  };

  const titleStyle = {
    fontSize: '6rem',
    margin: 0,
    animation: 'wobble 1s infinite alternate',
  };

  const messageStyle = {
    fontSize: '1.5rem',
    marginTop: '1rem',
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#ffffff',
    backgroundColor: '#5932EA',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>404</h1>
      <p style={messageStyle}>Page Not Found</p>
      <button style={buttonStyle} onClick={handleNavigate}>
        Go to Home
      </button>
      <style>
        {`
          @keyframes wobble {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(5deg); }
            100% { transform: rotate(-5deg); }
          }
          
          @keyframes float {
            0% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
    </div>
  );
};

export default NotFound;
