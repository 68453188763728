import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DisabledDropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import DataTransferService from "../../core/service/dataTransferService";
import DefaultProfile from "../../assets/images/defaultProfile.png";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import { useNavigate } from "react-router-dom";

const ManageUsers = () => {
  const dataTransferService = new DataTransferService();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const dataTransferService = new DataTransferService();
    const organisationName = dataTransferService.getSelectedOrganization();
    const userService = new UserService();
    const fetchUsers = (organisationName) => {
      setLoading(true);
      userService
        .fetchUsers(organisationName)
        .then((response) => {
          if (response.data.responseCode === 200) {
            setUsers(response.data.responseBody);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          handleErrorResponse("admin-fetch-users", error.response);
        });
    };
    fetchUsers(organisationName);
  }, [organisationName]);

  const handleProceed = () => {
    navigate("/invite-form");
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>

      <div className="custom-container mt-36 p-4">
        <p className="fs-4 fw-600">User Management</p>
        <div className="pt-20">
          <p className="fs-14 fw-500 pb-2">
            Select Organization <span className="color-star">*</span>{" "}
          </p>
          <DisabledDropdownComponent
            toggleClassName="fs-14 fw-semibold custom-white-dropdown-with-border custom-arrow"
            selected={organisationName}
          />
        </div>

        <div className="mt-4">
          <div>
            <button
              type="button"
              className="btn btn-outline custom-btn-outline"
              onClick={handleProceed}
            >
              + Invite User
            </button>
          </div>

          <div className="mt-4 fs-6 fw-500">
            <div className="d-flex align-items-center justify-content-between">
              <span style={{ flex: 2 }}>Users</span>
              <span style={{ flex: 1 }}>Permission Type</span>
              <span style={{ flex: 1 }}>Location Access</span>
              <span style={{ flex: 1 }}>Action</span>
            </div>
            <hr />

            {loading ? (
              <div className="full-screen-loader">
                <Loader loading={loading} />
              </div>
            ) : (
              users.map((user, index) => (
                <>
                  <div
                    key={index}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: 2 }}
                    >
                      <img
                        src={DefaultProfile}
                        alt="user-profile-icon"
                        className="rounded-circle me-3"
                        width={52}
                        height={52}
                      />
                      <div className="d-flex flex-column">
                        <span>{user.userName}</span>
                        <span>{user.userEmail}</span>
                      </div>
                    </div>
                    <span style={{ flex: 1 }}>{user.userRole}</span>
                    <span style={{ flex: 1 }}>
                      {user.accessibleBranchesCount} locations
                    </span>
                    <div
                      style={{ flex: 1 }}
                      className="d-flex justify-content-start"
                    >
                      <span className="me-3 pointer">
                        <FontAwesomeIcon icon={faEdit} className="me-1" /> Edit
                      </span>
                      <span className="pointer">
                        <FontAwesomeIcon icon={faTrash} className="me-1" />{" "}
                        Delete
                      </span>
                    </div>
                  </div>
                  <hr />
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUsers;
