import { useNavigate } from "react-router-dom";
import Car from "../../assets/images/car.png";
import Truck from "../../assets/images/truck.png";
import Profile from "../../common/profile";

const SelectVehicle = () => {
  const navigate = useNavigate();
  const handleCar = () => {
    navigate("/wheeler-billing");
  };
  const handleTruck = () => {
    navigate("/heavy-vehicle-billing");
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end">
        <Profile />
      </div>

      <div className="mt-86 select-vehicle-container p-4">
        <div className="pt-3">
          <p className="fs-22 fw-600">New Service</p>
          <p className="fs-22 fw-normal mt-10">Select the type of vehicle</p>
        </div>
        <div className="pt-3">
          <div className="d-flex justify-content-center align-items-center">
            <img src={Car} alt="car-icon"   onClick={handleCar} className="pointer gap-cards-vehicle" />
            <img src={Truck} alt="truck-icon"   onClick={handleTruck}  className="pointer"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectVehicle;
