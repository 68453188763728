import React, { useEffect, useState, useMemo } from "react";
import "../assets/scss/font-size.scss";
import "../assets/scss/font-weight.scss";
import DataTransferService from "../core/service/dataTransferService";
import Profile from "../common/profile";
import { DropdownComponent } from "../common/dropDown";
import Flex from "../common/flex";
import {
  Table,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { handleErrorResponse } from "../core/tokenInterceptor/axiosInstance";
import ServiceRequest from "../core/service/serviceRequest";
import Loader from "../core/loader/loader";
import { Link, useNavigate } from "react-router-dom";
import RecentServices from "./service-request/recentService";

const Billing = () => {
  const dataTransferService = useMemo(() => new DataTransferService(), []);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [sortOption, setSortOption] = useState("Newest");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [cashData, setCashData] = useState([]);
  const navigate = useNavigate();

  const serviceRequest = new ServiceRequest();
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const storedBranch = dataTransferService.getSelectedBranch();
    const branList = dataTransferService.getListOfBranches();
    if (branList) {
      const parsedBranches = branList.split(",");
      setBranches(parsedBranches);
      if (storedBranch) {
        setSelectedBranch(storedBranch);
        dataTransferService.setSelectedBranch(storedBranch);
      } else if (parsedBranches.length > 0) {
        const initialBranch = parsedBranches[0].trim();
        setSelectedBranch(initialBranch);
        dataTransferService.setSelectedBranch(initialBranch);
      }
    }
  }, [dataTransferService]);

  useEffect(() => {
    fetchService(sortOption);
    fetchCash(0,10,sortOption);
    searchService();
  }, [selectedBranch, sortOption]);

  useEffect(() => {
    if (searchTerm) {
      searchService(0, 10);
    } else {
      fetchService(sortOption, 0, 10);
      fetchService(sortOption, 0, 10);
    }
  }, [0, 10, sortOption, searchTerm]);

  const handleBranchSelect = (branch) => {
    const trimmedBranch = branch.trim();
    setSelectedBranch(trimmedBranch);
    dataTransferService.setSelectedBranch(trimmedBranch);
  };

  const handleRecentServices = () => {
    navigate("/recent-services");
  };



  const branchOptions = branches.map((branch, index) => ({
    id: index,
    value: branch.trim(),
    label: branch.trim(),
  }));

  const fetchService = (order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .fetchServices(
        order,
        0,
        10,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const searchService = () => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .searchServices(
        0,
        10,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        searchTerm
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchCash = (page,size,order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .paymentStatus(
       
        page,size,
        dataTransferService.getSelectedOrganization(),
        dataTransferService.getSelectedBranch(),order
      )
      .then((response) => {
        console.log(response.data.responseBody);
        setLoading(false);
        if (response.data.responseCode === 200) {
          setCashData(response.data.responseBody);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const handlenavigate = () => {
    navigate("/select-vehicle");
  };

  const handleCreditStatusPageNavigate = () => {
    navigate("/credit-transactions");
  };

  const navigateToCreditHistory = () => {
    navigate("/show-credit-history");
  };

  return (
    <>
      <Flex className="d-flex justify-content-between align-items-center pt-1">
        <DropdownComponent
          selected={selectedBranch}
          options={branchOptions}
          onSelect={handleBranchSelect}
          toggleClassName="fs-4 fw-500 custom-dropdown custom-arrow"
        />
        <Profile />
      </Flex>

      <div className="background-container">
        <div className="container mt-62">
          <div className="row show-amount-container">
            <div className="col">
              <div className=" h-100 p-4 shadow-sm  card-radius card-bg-color">
                <div className="card-title">
                  <div className="d-flex justify-content-between">
                  
                    <h5 className="amount-card-heading text-color">
                      Cash Transactions
                    </h5>
                  </div>
                  <hr />
                  <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(cashData.cashTotal || 0).toFixed(0)}`}</h3>       
                </div>
              </div>
            </div>
            <div className="col cash-cards-gap">
              <div className=" h-100 p-4 shadow-sm card-radius card-bg-color">
                <div className="card-title">
                  <div className="d-flex justify-content-between">
                 
                    <h5 className="amount-card-heading text-color">
                      Credit Transactions
                    </h5>
             
                  </div>
                  <hr />
                  <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(cashData.creditTotal || 0).toFixed(0)}`}</h3>
                  <a
                    className="pointer"
                    onClick={navigateToCreditHistory}
                    style={{ color: "#127BFF", textDecoration: "none" }}
                  >
                    View all →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-services-container mt-3 services-card-radius">
          <h3 className="padding-heading fw-bold">Recent Services</h3>
          <div className="d-flex justify-content-end mt-2">
            <div className="input-group w-25 pe-5">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <span className="input-group-text bg-white border-left-0">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              {searchTerm && (
                <span
                  className="input-group-text bg-white border-left-0 pointer"
                  onClick={clearSearch}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
              )}
            </div>
            {!searchTerm && (
              <DropdownButton
                id="dropdown-sort-button"
                className="fw-bold pe-3"
                title={`Sort by: ${sortOption}`}
                variant="outline-secondary"
              >
                <Dropdown.Item
                  className={sortOption === "Newest" ? "fw-bold" : ""}
                  onClick={() => setSortOption("Newest")}
                >
                  Newest
                </Dropdown.Item>
                <Dropdown.Item
                  className={sortOption === "Oldest" ? "fw-bold" : ""}
                  onClick={() => setSortOption("Oldest")}
                >
                  Oldest
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>
          <div className="d-flex justify-content-end me-4 mb-3 mt-32">
            <button
              className="btn btn-outline custom-btn-outline"
              onClick={handlenavigate}
            >
              + New Service
            </button>
          </div>
          <div className="table-responsive ms-4 me-4">
            <Table hover>
              <thead>
                <tr>
                  <th className="services-headers">Vehicle Number</th>
                  <th className="services-headers">Invoice Number</th>
                  <th className="services-headers">Category</th>
                  <th className="services-headers">Amount</th>
                  <th className="services-headers">Payment Type</th>
                  <th className="services-headers">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <div className="full-screen-loader">
                        <Loader loading={loading} />
                      </div>
                    </td>
                  </tr>
                ) : errorMessage ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      <div className="text-center text-danger">
                        {errorMessage}
                      </div>
                    </td>
                  </tr>
                ) : (
                  servicesData.map((service, index) => (
                    <tr key={index}>
                      <td className="p-3">
                        {service.invoiceData.vehicleNumber}
                      </td>
                      <td className="p-3">
                        {service.invoiceData.invoiceSerialNumber}
                      </td>
                      <td className="p-3">{service.vehicleType}</td>
                      <td className="p-3">{service.invoiceData?.totalAmount.toFixed(0) || service.invoiceData?.amount.toFixed(0)}</td>
                      <td className="p-3">
                        {service.paymentType ? service.paymentType : "N/A"}
                      </td>

                      <td className="p-3">
                        <Link
                          to={`/invoice/${service.invoiceData.invoiceSerialNumber}`}
                          style={{ color: "#127BFF", textDecoration: "none" }}
                   
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
          <div className="p-4">
            <p>
              Showing data 1 to {servicesData.length} of {servicesData.length}{" "}
              entries{" "}
              <span style={{ color: "#127BFF"}} onClick={handleRecentServices} className="pointer">
                View all
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
