import React, { useRef, useEffect, useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "../core/loader/loader";
import { handleErrorResponse } from "../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../core/service/dataTransferService";
import Flex from "../common/flex";
import Profile from "../common/profile";
import ServiceRequest from "../core/service/serviceRequest";
import InvoiceService from "../core/service/invoiceService";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Invoice = () => {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState("");
  const paymentTypeRef = useRef(null);
  const [creditStatus, setCreditStatus] = useState("unsettled");

  const serviceRequest = new ServiceRequest();
  const invoiceService = new InvoiceService();
  const dataTransferService = new DataTransferService();
  const { invoiceSerialNumber } = useParams();
  console.log(creditStatus);
  console.log(invoiceData.creditStatus);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const invoiceResponse = await serviceRequest.fetchInvoice(
          invoiceSerialNumber,
          dataTransferService.getSelectedBranch(),
          dataTransferService.getSelectedOrganization()
        );
        if (invoiceResponse.data.responseCode === 200) {
          setInvoiceData(invoiceResponse.data.responseBody);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        handleErrorResponse("invoice", error.response);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (invoiceData?.creditStatus) {
      setCreditStatus(invoiceData.creditStatus.toLowerCase());
    }
  }, [invoiceData]);

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!invoiceData) {
    return <p>No invoice data found.</p>;
  }

  const isoDateString = new Date().toLocaleDateString("en-GB");

  const upcominAlertDate = invoiceData.upcomingAlert
    ? invoiceData.upcomingAlert.split("-").reverse().join("/")
    : null;

  const handleSaveAndPrint = () => {
    window.print();
  };

  const handleChangeCreditStatus = () => {
    setCreditStatus((prevStatus) =>
      prevStatus === "unsettled" ? "settled" : "unsettled"
    );
  };

  const handlePaymentTypeChange = async () => {
    const newPaymentType = paymentTypeRef.current.value;
    setPaymentType(newPaymentType);
    console.log(newPaymentType);

    try {
      await invoiceService.updatePaymentType(
        invoiceSerialNumber,
        newPaymentType.toUpperCase(),
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
      console.log("Payment type updated successfully");
    } catch (error) {
      console.error("Error updating payment type:", error);
      handleErrorResponse("invoice", error.response);
    }
  };

  const updateCreditStatus = async () => {
    setCreditStatus(creditStatus);
    try {
      await invoiceService.updateCreditstatus(
        creditStatus.toUpperCase(),
        invoiceSerialNumber,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      );
      toast.success("Credit status updated successfully.", {
        theme: "colored",
      });
    } catch (error) {
      console.error("Error updating credit status:", error);
      handleErrorResponse("invoice", error.response);
    }
  };

  const currentStatus = creditStatus || invoiceData.creditStatus?.toLowerCase();
  const isSettled = currentStatus === "settled";

  const serviceRows = [
    {
      condition: invoiceData.serviceDTO.weightBalancingQuantity,
      name: "Weight Balancing",
      quantity: invoiceData.serviceDTO.weightBalancingQuantity,
      price: invoiceData.serviceDTO.weightBalancingPrice,
      totalPrice: invoiceData.serviceDTO.weightBalancingTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.wheelBalancingQuantity,
      name: "Wheel Balancing",
      quantity: invoiceData.serviceDTO.wheelBalancingQuantity,
      price: invoiceData.serviceDTO.wheelBalancingPrice,
      totalPrice: invoiceData.serviceDTO.wheelBalancingTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.tyrePunctureQuantity,
      name: "Tyre Puncture",
      quantity: invoiceData.serviceDTO.tyrePunctureQuantity,
      price: invoiceData.serviceDTO.tyrePuncturePrice,
      totalPrice: invoiceData.serviceDTO.tyrePunctureTotalPrice,
    },
    {
      condition: invoiceData.serviceDTO.othersQuantity,
      name: "Other services",
      quantity: invoiceData.serviceDTO.othersQuantity,
      price: invoiceData.serviceDTO.othersPrice,
      totalPrice: invoiceData.serviceDTO.othersTotalPrice,
    },
  ].filter((row) => row.condition);

  return (
    <div>
      <Flex className=" profile d-flex justify-content-end align-items-center pt-1">
        <Profile />
      </Flex>
      <div className="whole-container d-flex justify-content align-items-sm-start">
        <div className="left-section">
          <div className="in-containerpage">
            <div className="businessdetails d-flex justify-content-between align-items-top-center">
              <div className="imageclass">
                <img
                  className="img-fluid"
                  src={
                    dataTransferService.getBloburl() +
                    invoiceData.organisationProfile
                  }
                  alt="Invoice"
                  width={130}
                  height={130}
                />
              </div>
              <div className="details1">
                <p className="fs-14 fw-600">
                  {dataTransferService.getSelectedOrganization()}
                </p>
                <p className="text-muted">
                  {dataTransferService.getSelectedBranch()}
                </p>
                <p className="text-muted">{invoiceData.branchAddress}</p>
                <p className="text-muted">{invoiceData.branchMobileNumber}</p>
              </div>
              <div className="details2 text-right">
                <p className="fs-14 fw-500 dark-slate-gray">
                  Invoice number: {invoiceData.invoiceSerialNumber}
                </p>
                <p className="text-muted">Date: {isoDateString}</p>
              </div>
            </div>
            <div className="bill-container border border-light">
              <div className="tobill">
                <p className="text-muted">Bill To :</p>
                <p className="small">{invoiceData.customerName}</p>
                {invoiceData.gstinNumber && (
                  <p className="small">GSTIN :{invoiceData.gstinNumber}</p>
                )}
                <p className="text-muted pt-1">{invoiceData.vehicleNumber}</p>
                <p className="text-muted pt-1">{invoiceData.vehicleModel}</p>
                <p className="text-muted pt-1">
                  {invoiceData.customerMobileNumber}
                </p>
                <p className="text-muted pt-4">Note:</p>
                {paymentType ? (
                  <p className="smalltext pt-1">
                    For optimized and smooth rides, please ensure the next wheel
                    alignment is after 4000kms from the current reading.
                  </p>
                ) : (
                  <p className="smalltext pt-1">
                    For optimized and smooth rides, please ensure the next wheel
                    alignment is on or before {upcominAlertDate || "dd/mm/yy"}.
                  </p>
                )}
              </div>
              <table className="table custom-table mt-4">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Services</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceRows.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{row.name}</td>
                      <td>
                        {row.quantity}
                        <p className="text-muted">Unit(s)</p>
                      </td>
                      <td>₹{row.price}</td>
                      <td>₹{row.totalPrice}</td>
                    </tr>
                  ))}
                  <tr className="total-row">
                    <td colSpan="3" className="text-right font-weight-bold"></td>
                    <td className="font-weight-bold">
                      <strong>
                        {invoiceData.gstinNumber ? "Sub-Total" : "Total "}:{" "}
                      </strong>
                    </td>
                    <td className="font-weight-bold">
                      <strong>₹{invoiceData.amount.toFixed(0)}</strong>
                    </td>
                  </tr>
                  {invoiceData.gstinNumber && (
                    <>
                      <tr className="total-row">
                        <td colSpan="3" className="text-right font-weight-bold"></td>
                        <td className="font-weight-bold">
                          <strong>CGST 9%: </strong>
                        </td>
                        <td className="font-weight-bold">
                          ₹{invoiceData.cgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3" className="text-right font-weight-bold"></td>
                        <td className="font-weight-bold">
                          <strong>SGST 9%: </strong>
                        </td>
                        <td className="font-weight-bold">
                          ₹{invoiceData.sgst.toFixed(0)}
                        </td>
                      </tr>
                      <tr className="total-row">
                        <td colSpan="3" className="text-right font-weight-bold"></td>
                        <td className="font-weight-bold">
                          <strong>Total Amount: </strong>
                        </td>
                        <td className="font-weight-bold">
                          ₹{invoiceData.totalAmount.toFixed(0)}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="right-section">
          <div className="right-container d-flex flex-column">
            <p className="h5">
              <strong>
                {paymentType ||
                invoiceData.paymentType === "CASH" ||
                invoiceData.paymentType === "CREDIT"
                  ? "Customer Details"
                  : "Next Service Alert"}
              </strong>
            </p>
            <hr />
            <div className="alert-payment-container d-flex flex-column">
              {(paymentType || invoiceData.paymentType) && (
                <>
                  <p className="mt-3">
                    Customer Name: {invoiceData.customerName}
                  </p>
                  <p className="mt-3">
                    Mobile Number: {invoiceData.customerMobileNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Number: {invoiceData.vehicleNumber}
                  </p>
                  <p className="mt-3">
                    Vehicle Model: {invoiceData.vehicleModel}
                  </p>
                  <p className="mt-3">
                    Payment Type:{" "}
                    {invoiceData.paymentType?.toLowerCase() || paymentType}
                  </p>

                  {(paymentType === "credit" ||
                    invoiceData.paymentType === "CREDIT") && (
                    <>
                      <p className="mt-3">
                        Credit Status:
                        <span
                          className={`ms-2 d-inline-block p-1 rounded border credit-status ${
                            isSettled
                              ? "credit-status-settled"
                              : "credit-status-unsettled"
                          }`}
                        >
                          {currentStatus}
                        </span>
                        <button
                          className="btn btn-link"
                          onClick={handleChangeCreditStatus}
                        >
                          Change
                        </button>
                      </p>
                      <button
                        className="save-btn2"
                        onClick={updateCreditStatus}
                      >
                        Update
                      </button>
                    </>
                  )}

                  <button className="save-btn2" onClick={handleSaveAndPrint}>
                    Print Invoice
                  </button>
                </>
              )}
            </div>
            {!paymentType && !invoiceData.paymentType && (
              <>
                <p>
                  Average Kms of Vehicle: {invoiceData.vehicleAveragekmsPerday}{" "}
                  kms/day
                </p>
                <p className="mt-3">
                  Next {invoiceData.vehicleOdometerReading} kms on:{" "}
                  {upcominAlertDate}
                </p>
                <select ref={paymentTypeRef} className="w-50 form-select mt-3">
                  <option value="payment Type">Payment Type</option>
                  <option value="cash">Cash</option>
                  <option value="credit">Credit</option>
                </select>
                <button className="save-btn" onClick={handlePaymentTypeChange}>
                  Save and Print Invoice
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
