import { Appconfig } from "../config/appConfig";
import axiosInstance from "../tokenInterceptor/axiosInstance";

export class ServiceRequest {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + "service-request";
  }

  fetchServices(order, page, size, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/service`, {
      params: { order, page, size, branchName, organisationName },
    });
  }

  searchServices(page, size, branchName, organisationName, vehicleNumber) {
    return axiosInstance.get(`${this.Api}/search`, {
      params: { page, size, branchName, organisationName, vehicleNumber },
    });
  }

  alertByVehicleNumber(branchName, organisationName, vehicleNumber) {
    return axiosInstance.get(`${this.Api}/alert`, {
      params: { branchName, organisationName, vehicleNumber },
    });
  }

  serviceHistory(branchName, organisationName, vehicleNumber, page, size) {
    return axiosInstance.get(`${this.Api}/history`, {
      params: { branchName, organisationName, vehicleNumber, page, size },
    });
  }

  paymentStatus(page,size,organisationName,branchName,order) {
    return axiosInstance.get(`${this.Api}/payment-status`, {
      params: {page,size,organisationName,branchName,order},
    });
  }

  fetchInvoice(invoiceSerialNumber, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}`, {
      params: { invoiceSerialNumber, branchName, organisationName },
    });
  }

  fetchAlert(vehicleNumber, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/alert`, {
      params: { vehicleNumber, branchName, organisationName },
    });
  }

  searchCreditDetails(vehicleNumber,page,size,branchName,organisationName){
    return axiosInstance.get(`${this.Api}/search-credit`,{
      params:{vehicleNumber,page,size,branchName,organisationName},
    })
  }
}

export default ServiceRequest;
