import React, { useState, useEffect } from "react";
import DataTransferService from "../core/service/dataTransferService";
import "../assets/scss/font-size.scss";
import "../assets/scss/font-weight.scss";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ServiceRequest from "../core/service/serviceRequest";
import Loader from "../core/loader/loader";
import { PaginationComponent } from "../common/pagination";
import { handleErrorResponse } from "../core/tokenInterceptor/axiosInstance";
import { useNavigate } from "react-router-dom";
import { DropdownComponent } from "../common/dropDown";
import Flex from "../common/flex";
import Profile from "../common/profile";

const ShowCreditHistory = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const dataTransferService = new DataTransferService();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("Newest");
  const [creditData, setCreditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const serviceRequest = new ServiceRequest();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();

  const fetchCreditData = (page, size, order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .paymentStatus(page, size, organisationName, branchName, order)
      .then((response) => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          setCreditData(response.data.responseBody.settledInvoices);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const searchCreditData = (vehicleNumber, page, size) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .searchCreditDetails(
        vehicleNumber,
        page,
        size,
        branchName,
        organisationName
      )
      .then((response) => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          setCreditData(response.data.responseBody.settledInvoices);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      searchCreditData(searchTerm, pageIndex, pageSize, sortOption);
    } else {
      fetchCreditData(pageIndex, pageSize, sortOption);
    }
  }, [pageIndex, pageSize, searchTerm, sortOption]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPageIndex(0);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setPageIndex(0);
  };

  const handleSortChange = (option) => {
    setSortOption(option);
    setPageIndex(0);
  };

  const handleNavigate = () => {
    navigate("/select-vehicle");
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  return (
    <div className="background-container">
      <Flex className="d-flex justify-content-end align-items-center pt-1">
     <Profile/>
      </Flex>
      <div className="bg-services-container ms-1 mr-28 pe-4 pb-3 mt-17 mb-4 services-card-radius">
        <h3 className="padding-heading fw-bold">Credit Services History</h3>

        <Flex className="pt-3 align-items-center justify-content-end">
          <div className="input-group w-25 pe-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className="input-group-text bg-white border-left-0">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            {searchTerm && (
              <span
                className="input-group-text bg-white border-left-0 pointer"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </div>

          {!searchTerm && (
            <DropdownComponent
              selected={`Sort by: ${sortOption}`}
              options={selectOptions}
              onSelect={handleSortChange}
              toggleClassName="fs-14 fw-semibold custom-white-dropdown"
            />
          )}
        </Flex>

        <div className="d-flex justify-content-end me-4 mb-3 mt-32">
          <button
            className="btn btn-outline custom-btn-outline new-service-button"
            onClick={handleNavigate}
          >
            + New Service
          </button>
        </div>
        <div className="table-responsive ms-4 me-4">
          <Table hover>
            <thead>
              <tr>
                <th className="services-headers">Vehicle Number</th>
                <th className="services-headers">Invoice Number</th>
                <th className="services-headers">Category</th>
                <th className="services-headers">Amount</th>
                <th className="services-headers">Payment Type</th>
                <th className="services-headers">Invoice</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <Loader loading={loading} />
                  </td>
                </tr>
              ) : errorMessage ? (
                <tr>
                  <td colSpan="6" className="text-center text-danger">
                    {errorMessage}
                  </td>
                </tr>
              ) : (
                creditData?.map((service, index) => (
                  <tr key={index}>
                    <td className="p-3">{service.vehicleNumber}</td>
                    <td className="p-3">{service.invoiceSerialNumber}</td>
                    <td className="p-3">{service.vehicleType}</td>
                    <td className="p-3">{service.amount.toFixed(0)}</td>
                    <td className="p-3">{service.paymentType}</td>
                    <td className="p-3">
                      <a
                        href="click"
                        style={{ color: "#127BFF", textDecoration: "none" }}
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        <div className="p-4 d-flex justify-content-between">
          <p className="services-headers">
            Showing {pageIndex * pageSize + 1} to{" "}
            {Math.min((pageIndex + 1) * pageSize, totalItems)} of {totalItems}{" "}
            entries
          </p>
          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
            isPrevDisabled={pageIndex === 0}
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowCreditHistory;
