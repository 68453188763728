import Tabs from "react-bootstrap/Tabs";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import UpComingAlerts from "./upComingAlerts";
import ServiceHistory from "./serviceHistory";
import { useParams } from "react-router-dom";
function AlertsAndHistory() {
  const [activeTab, setActiveTab] = useState("upComingAlerts");
  const { vehicleNumber } = useParams();

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const customStyles = {
    tabContainer: {
      padding: "1rem", 
    },
    tabTitle: {
      paddingBottom: "0.5rem", 
      cursor: "pointer",
    },
    activeTab: {
      textDecoration: "none",
      borderBottom: "3px solid #5932EA",
    },
    inactiveTab: {
      textDecoration: "none",
    },
  };

  return (
    <div className="p-4 select-vehicle-container" style={customStyles.tabContainer}>
      <span className="fs-22 fw-600">{vehicleNumber}</span>
      <hr className="color" />

      <div className="mt-4">
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          className="custom-tabs"
        >
          <Tab
            eventKey="upComingAlerts"
            title={
              <span
                style={{
                  ...customStyles.tabTitle,
                  ...(activeTab === "upComingAlerts" ? customStyles.activeTab : customStyles.inactiveTab),
                }}
              >
                Up Coming Alerts
              </span>
            }
          >
            <UpComingAlerts vehicleNumber={vehicleNumber} />
          </Tab>
          <Tab
            eventKey="serviceHistory"
            title={
              <span
                style={{
                  ...customStyles.tabTitle,
                  ...(activeTab === "serviceHistory" ? customStyles.activeTab : customStyles.inactiveTab),
                }}
              >
                Service History
              </span>
            }
          >
            <ServiceHistory vehicleNumber={vehicleNumber} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default AlertsAndHistory;
