import React, { useEffect, useState } from "react";
import Profile from "../../common/profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom"; 
import DataTransferService from "../../core/service/dataTransferService";

const ManageOrganisation = () => {
  const [orgData, setOrgData] = useState([]);
  const navigate = useNavigate();

  const getOrgAndBranches = () => {
    const storedData = localStorage.getItem("orgAndBran");
    return storedData ? JSON.parse(storedData) : [];
  };

  const handleProceed = () => {
    navigate("/create-organisation");
  };

  useEffect(() => {
    const organizations = getOrgAndBranches();
    setOrgData(organizations);
  }, []);

  const handleNavigate = (organisationName) => {
    navigate("/manage-branches", { state: { organisationName } });
  };

  const dataTransferService = new DataTransferService();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>
      <div className="custom-container mt-36 p-4">
        <div>
          <p className="fs-4 fw-600">Organisations</p>
        </div>
        <div className="pt-40 d-flex justify-content-end">
          <button type="button" className="btn btn-outline custom-btn-outline"  onClick={handleProceed}>
            + Add Organisation
          </button>
        </div>
        <div className="pt-40">
          <p className="pt-3">Here’s your list of organisations</p>
        </div>
        {orgData.length > 0 ? (
          orgData.map((org, index) => (
            <div key={index} className="pt-32 d-flex align-items-center pointer" onClick={() => handleNavigate(org.organisationName)} >
              <img
                src={dataTransferService.getBloburl() + org.organisationProfile}
                alt="user-profile-icon"
                width={50}
                height={50}
              />
              <div className="d-flex flex-column pe-67 ps-4 w-100">
                <span className="fs-4 fw-600">{org.organisationName}</span>
                <span className="fs-6 fw-400">
                  {org.branches ? org.branches.length : 0} locations
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="pointer"
                />
              </div>
            </div>
          ))
        ) : (
          <p>No organisations found</p>
        )}
      </div>
    </>
  );
};

export default ManageOrganisation;
