import axios from 'axios';
import DataTransferService from '../service/dataTransferService';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  config => {
    const dataTransferService = new DataTransferService();
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    const token = dataTransferService.getUuid();
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const handleErrorResponse = (methodName, response) => {
  if (
    response.data.responseCode === 400 ||
    response.data.responseCode === 500 ||
    response.data.responseCode === 409 
  ) {
    toast.error(
      `${methodName} ${response.data.responseCode} ${response.data.responseMessage}`
    );
  }
};
