import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import DataTransferService from "../../core/service/dataTransferService";
import { useFormik } from "formik";
import { createBranchesValidation } from "../../validations/forms";

const CreateBranches = () => {
  const location = useLocation();
  const { organisationName: organisationNameFromState } = location.state || {};
  const [profile, setProfile] = useState("");
  const [organisationName, setOrganisationName] = useState(
    organisationNameFromState || ""
  );
  const [numLocations, setNumLocations] = useState(0);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const dataTransferService = new DataTransferService();
  const [isFormVisible, setFormVisible] = useState(false);
  const [showLocationInput, setShowLocationInput] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const organisationDTO = JSON.parse(localStorage.getItem("organisationDTO"));
    if (organisationDTO) {
      setOrganisationName(organisationDTO.organisationName || "");
      setNumLocations(organisationDTO.numLocations || 0); 
      if (organisationDTO.numLocations && organisationDTO.firstTime === false) {
        setShowLocationInput(true);
      } else {
        setFormVisible(false); 
      }
    }
  }, [organisationNameFromState]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await dataTransferService.getOrgProfileImage();
        setProfile(userProfile || "");
      } catch (error) {
        console.error("Error fetching user profile name:", error);
      }
    };
    fetchUserProfile();
  }, []);

  const handleFormOpen = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
    setCurrentFormIndex(0);
  };

  const handleNextForm = () => {
    setCurrentFormIndex((prevIndex) => prevIndex + 1);
  };

  const formik = useFormik({
    initialValues: {
      branchName: "",
      branchAddress: "",
      branchMobileNumber: "",
    },
    validationSchema: createBranchesValidation,
    onSubmit: (values, { resetForm }) => {
      const newBranch = {
        branchName: values.branchName,
        branchAddress: values.branchAddress,
        branchMobileNumber: values.branchMobileNumber,
      };
  
      const organisationDTO = JSON.parse(
        localStorage.getItem("organisationDTO")
      ) || { branchDTOList: [] };
  
      organisationDTO.branchDTOList.push(newBranch);
      localStorage.setItem("organisationDTO", JSON.stringify(organisationDTO));
      if (currentFormIndex + 1 >= numLocations) {
        organisationDTO.firstTime = false;
        localStorage.setItem("organisationDTO", JSON.stringify(organisationDTO));
        handleFormClose();
        navigate("/view-branches");
      } else {
        resetForm();
        handleNextForm();
      }
    },
  });

  const handleNumLocationsSubmit = (event) => {
    event.preventDefault();
    setShowLocationInput(false);
    setFormVisible(true); 
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handlePopState = (event) => {
      const confirmLeave = window.confirm(
        "Are you sure you want to leave this page?"
      );
      if (!confirmLeave) {
        window.history.pushState(null, "", window.location.href);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-center full-height page-content ${isFormVisible ? "blurred" : ""}`}
      >
        <div className="view-branches-container p-4">
          <div className="d-flex align-items-center justify-content-between">
            <p className="fs-22 fw-600">Hi {dataTransferService.getUserName()}</p>
            <img
              src={dataTransferService.getBloburl() + profile}
              alt="user-profile-icon"
              className="rounded-circle me-3"
              width={42}
              height={42}
            />
          </div>
          <hr />

          {showLocationInput && (
            <form onSubmit={handleNumLocationsSubmit}>
              <div className="mt-5 d-flex align-items-center justify-content-center">
                <div className="create-branches-container">
                  <p>You already have {numLocations} locations. How many more would you like to add?</p>
                  <input
                    type="number"
                    min="1"
                    value={numLocations || ""}
                    onChange={(e) => setNumLocations(parseInt(e.target.value))}
                    className="form-control"
                    placeholder="Enter number of locations"
                    required
                  />
                  <button type="submit" className="btn btn-primary mt-3">
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          )}

          {!showLocationInput && (
            <div className="mt-5 d-flex align-items-center justify-content-center">
              <div className="create-branches-container">
                <div className="d-flex align-items-center justify-content-center ">
                  <p className="fs-4 fw-600">
                    {organisationName || "Organisation Name"}
                  </p>
                  <button
                    type="button"
                    className="btn btn-outline custom-btn-outline ms-4"
                    onClick={handleFormOpen}
                  >
                    + Add location
                  </button>
                </div>
                <div className="pt-40 w-100 ">
                  <p className="pt-3 fs-6 fw-600">Your locations</p>
                </div>
                <div className="pt-4 w-100">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by location, name"
                      disabled
                    />
                    <span className="input-group-text bg-white border-left-0">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isFormVisible && (
        <div className="modal-overlay">
          <div className="loc-form-container">
            <h2 className="loc-form-title">
              Add new location ({currentFormIndex + 1} / {numLocations})
            </h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="loc-input-container">
                <p>
                  Enter your Business Name<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className="loc-input-field"
                  name="businessName"
                  placeholder={organisationName || "Organisation Name"}
                  disabled
                />
                <p>
                  Branch Name<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={`loc-input-field ${formik.touched.branchName && formik.errors.branchName ? "error" : ""}`}
                  name="branchName"
                  placeholder="Enter Your Branch Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branchName}
                />
                {formik.touched.branchName && formik.errors.branchName && (
                  <div className="error-message">{formik.errors.branchName}</div>
                )}

                <p>
                  Address<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={`loc-input-field ${formik.touched.branchAddress && formik.errors.branchAddress ? "error" : ""}`}
                  name="branchAddress"
                  placeholder="Enter Your Branch Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branchAddress}
                />
                {formik.touched.branchAddress && formik.errors.branchAddress && (
                  <div className="error-message">{formik.errors.branchAddress}</div>
                )}

                <p>
                  Mobile Number<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  className={`loc-input-field ${formik.touched.branchMobileNumber && formik.errors.branchMobileNumber ? "error" : ""}`}
                  name="branchMobileNumber"
                  placeholder="Enter Mobile Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.branchMobileNumber}
                />
                {formik.touched.branchMobileNumber && formik.errors.branchMobileNumber && (
                  <div className="error-message">{formik.errors.branchMobileNumber}</div>
                )}
                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={handleFormClose}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateBranches;
