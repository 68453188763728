import DataTransferService from '../service/dataTransferService';
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

class AuthGuard extends React.Component {
  constructor(props) {
    super(props);
    this.dataTransferService = new DataTransferService();
  }

  isAuthenticated() {
    const uuid = this.dataTransferService.getUuid();
    const orgAndBran = this.dataTransferService.getOrgAndBranches("orgAndBran");
    const userRole = this.dataTransferService.getUserRole();
    return { uuid, orgAndBran, userRole };
  }

  render() {
    const { element: Element, ...rest } = this.props;
    const { uuid } = this.isAuthenticated();

    if (!uuid) {
      return <Navigate to="/login" replace />;
    }

    return <Element {...rest} />;
  }
}

AuthGuard.propTypes = {
  element: PropTypes.elementType.isRequired,
};

export default AuthGuard;
