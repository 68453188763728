import { Appconfig } from "../config/appConfig";
import axiosInstance from "../tokenInterceptor/axiosInstance";

export class UserService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + "user";
  }

  login(formData) {
    const body = JSON.stringify(formData);
    return axiosInstance.post(this.Api + "/login", body, {});
  }

  fetchBranches(organisationName) {
    return axiosInstance.get(`${this.Api}/branches-by`, {
      params: { organisationName },
    });
  }

  fetchUsers(organisationName) {
    return axiosInstance.get(`${this.Api}/admin-fetch-users`, {
      params: { organisationName },
    });
  }

  inviteUser(data) {
    const { organisationName, userRole, ...bodyData } = data;
    return axiosInstance.post(`${this.Api}/admin-invite`, bodyData, {
      params: {
        organisationName,
        userRole,
      },
    });
  }

  createOrganisation(data) {
    return axiosInstance.post(
      `${this.Api}/admin-create-organisation`,
      data,
      {}
    );
  }

  fetchOrgAndBranName() {
    return axiosInstance.get(this.Api + "/admin-fetch-org");
  }

  setPassword(body, userEmail) {
    return axiosInstance.post(this.Api + "/set-password", body, {
      params: {
        userEmail,
      },
    });
  }

  sendRemindNow(customerName, vehicleModel) {
    return axiosInstance.post(this.Api + "/send-remind-now", {}, {
      params: { customerName, vehicleModel },
    });
  }

  forgotPassword(userEmail) {
    return axiosInstance.post(this.Api + "/forgot-password", {}, {
      params: { userEmail },
    });
  }

  verifyOtp(userEmail, otp) {
    return axiosInstance.post(this.Api + "/verify-otp", {}, {
      params: { userEmail, otp },
    });
  }
}

export default UserService;
