import { Appconfig } from "../config/appConfig";
import axiosInstance from "../tokenInterceptor/axiosInstance";

export class ReturnTrackerRequest {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + "return-tracker";
  }

  fetchReturnCountsForBranch(branchName,organisationName){
    return axiosInstance.get(`${this.Api}/count`,{
        params:{branchName,organisationName}
    });
  }

  searchReturnsBySerialNumber(organisationName,branchName,serialNumber,page,size){
    return axiosInstance.get(`${this.Api}/find-by`,{
        params:{organisationName,branchName,serialNumber,page,size}
    });
  }

  sortReturnRequests(order,organisationName,branchName,page,size){
    return axiosInstance.get(`${this.Api}/return-requests`,{
        params:{order,organisationName,branchName,page,size}
    })
  }
 
}

export default ReturnTrackerRequest;
