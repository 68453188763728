import PropTypes from "prop-types";
import { Dropdown } from 'react-bootstrap';

export const DropdownComponent = ({
  selected = "all", 
  options,
  onSelect,
  toggleClassName = '',
}) => {
  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle className={toggleClassName}>
        {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((option) => (
          <Dropdown.Item key={option.id} eventKey={option.value}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownComponent.propTypes = {
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  customStyle: PropTypes.bool,
  toggleClassName: PropTypes.string,
};

export const DisabledDropdownComponent = ({
  selected = "Select an option", 
  toggleClassName = '',
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle disabled className={toggleClassName} >
        {selected} 
      </Dropdown.Toggle>
      <Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

DisabledDropdownComponent.propTypes = {
  selected: PropTypes.string,
  toggleClassName: PropTypes.string,
};