import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import"./assets/scss/headings.scss";
import is from "is_js";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { CloseButton } from "./common/toast";
import 'react-toastify/dist/ReactToastify.min.css';
import RetailersRoutes from "./routes";
import SideNav from "../src/common/sideNav";
import "bootstrap/dist/css/bootstrap.min.css";
import DataTransferService from "./core/service/dataTransferService";

const App = () => {
  const dataTransferService = new DataTransferService();
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    if (is.safari()) {
      HTMLClassList.add("safari");
    }
  }, [HTMLClassList]);

  const isLoggedIn = dataTransferService.getUuid() !== null;
  const location = useLocation();
  const pathsWithoutSideNav = [
    "/create-organisation",
    "/review-organisation",
    "/view-branches",
    "/create-branches",
    "/invite-users",
    "/invite-form",
    '/errors/404'
  ];
  const hideSideNav = pathsWithoutSideNav.includes(location.pathname);

  return (
    <div className="app-layout">
      <div className="sideNav ">{isLoggedIn && !hideSideNav && <SideNav />}</div>

      <div className="content-area p-4 text-2xl">
        <RetailersRoutes />
      </div>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <App />
    </Router>
  );
};

export default AppWrapper;
