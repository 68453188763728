import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Profile from "../../common/profile";
import { newReturnTrackerValidations } from "../../validations/forms";
import ReturnTrackerService from "../../core/service/returnTrackerService";
import DataTransferService from "../../core/service/dataTransferService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";

const NewReturnTracker = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const returnTrackerService = new ReturnTrackerService();
  const dataTransferService = new DataTransferService();

  const navigate = useNavigate();

  const initialValues = {
    serialNumber: "",
    manufacturerReferenceId: "",
    tyreManufacturer: "",
    tyreModel: "",
    customerName: "",
    customerMobileNumber: "",
    returnStatus: "",
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async (values) => {
    const formattedData = {
      serialNumber: values.serialNumber,
      manufacturerReferenceId: values.manufacturerReferenceId,
      tyreManufacturer: values.tyreManufacturer,
      tyreModel: values.tyreModel,
      returnStatus: values.returnStatus,
      customerDTO: {
        customerName: values.customerName,
        customerMobileNumber: values.customerMobileNumber,
      },
    };
    submitForm(formattedData);
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    const branchName = dataTransferService.getSelectedBranch();
    const organisationName = dataTransferService.getSelectedOrganization();
    returnTrackerService
      .createReturnTracker(formattedData, branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          navigate("/return-tracker");
          toast.success(`${response.data.responseMessage}`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse(error.response);
      });
  };

  return (
    <div className="background-container">
      <div className="d-flex justify-content-end">
        <Profile />
      </div>
      <div className="container new-return-tracker-container p-4">
        <p className="ms-3 fw-600 fs-22 heading-ff-poppins">
          Create New Return Tracker
        </p>
        <div>
          <hr className="pt-3 hr-color"></hr>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={newReturnTrackerValidations}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="row pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-600">
                    Serial Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="serialNumber"
                    placeholder="Enter Tyre Serial Number"
                    className="form-control narrow-input"
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-600">
                    Manufacturer Reference Id
                    <span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="manufacturerReferenceId"
                    placeholder="Enter Reference Tracker Id"
                    className="form-control narrow-input"
                  />
                  <ErrorMessage
                    name="manufacturerReferenceId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-600">
                    Tyre Manufacturer<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreManufacturer"
                    className="form-select narrow-input"
                  >
                    <option value="">Select Manufacturer</option>
                    <option value="MICHELIN">MICHELIN</option>
                    <option value="BRIDGESTONE">BRIDGESTONE</option>
                    <option value="GOODYEAR">GOODYEAR</option>
                  </Field>
                  <ErrorMessage
                    name="tyreManufacturer"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col">
                  <label className="label-text fw-600">
                    Tyre Model<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="tyreModel"
                    className="form-select narrow-input"
                  >
                    <option value="">Select Model</option>
                    <option value="MICHELIN_PILOT_SPORT">
                      MICHELIN_PILOT_SPORT
                    </option>
                    <option value="MICHELIN_PRIMACY_4">
                      MICHELIN_PRIMACY_4
                    </option>
                    <option value="MICHELIN_DEFENDER_T_H">
                      MICHELIN_DEFENDER_T_H
                    </option>
                    <option value="BRIDGESTONE_TURANZA_T005">
                      BRIDGESTONE_TURANZA_T005
                    </option>
                    <option value="BRIDGESTONE_DUELER_HL">
                      BRIDGESTONE_DUELER_HL
                    </option>
                    <option value="GOODYEAR_ASSURANCE_WEATHERREADY">
                      GOODYEAR_ASSURANCE_WEATHERREADY
                    </option>
                  </Field>
                  <ErrorMessage
                    name="tyreModel"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-600">
                    Customer Name<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerName"
                    placeholder="Enter Customer Name"
                    className="form-control narrow-input"
                  />
                  <ErrorMessage
                    name="customerName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-600">
                    Customer Mobile Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerMobileNumber"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control narrow-input"
                  />
                  <ErrorMessage
                    name="customerMobileNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-600">Created On</label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control narrow-input"
                    value={currentDate}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-600">
                    Return Status<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="returnStatus"
                    className="form-select narrow-input"
                  >
                    <option value="">Select Status</option>
                    <option value="IN_PROCESS">IN_PROCESS</option>
                    <option value="PASSED_QC">PASSED_QC</option>
                    <option value="FAILED_QC">FAILED_QC</option>
                  </Field>
                  <ErrorMessage
                    name="returnStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className=" mt-4 mb-3 d-flex justify-content-end">
                <button
                  type="submit"
                  className="button-indigo"
                  disabled={loading}
                >
                  {loading ? "Creating..." : "Create"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewReturnTracker;
