import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { wheelerBillingValidations } from "../../validations/forms";
import DataTransferService from "../../core/service/dataTransferService";
import Profile from "../../common/profile";
import Flex from "../../common/flex";
import InvoiceService from "../../core/service/invoiceService";
import { toast } from "react-toastify";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { useNavigate } from "react-router-dom";

const WheelerBilling = () => {
  const initialValues = {
    services: {
      wheelBalancing: {
        selected: false,
        wheelBalancingQuantity: "",
        wheelBalancingPrice: "",
      },
      weightBalancing: {
        selected: false,
        weightBalancingQuantity: "",
        weightBalancingPrice: "",
      },
      tyrePuncture: {
        selected: false,
        tyrePunctureQuantity: "",
        tyrePuncturePrice: "",
      },
      others: { selected: false, quantity: "", price: "" },
    },
    customerName: "",
    customerMobileNumber: "",
    driverMobileNumber: "",
    vehicleNumber: "",
    vehicleManufacturer: "",
    vehicleModel: "",
    vehicleOdometerReading: "",
    vehicleAverageKmsPerDay: "",
    useGstInvoice: false,
    gstinNumber: "",
    businessName: "",
    createdOn: "",
  };

  const dataTransferService = new DataTransferService();
  const invoiceService = new InvoiceService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async (values) => {
    const formattedData = {
      customerDTO: {
        customerName: values.customerName,
        customerMobileNumber: values.customerMobileNumber,
      },
      businessDetailsDTO: {
        businessName: values.businessName || "",
        gstinNumber: values.gstinNumber || "",
      },
      vehicleDTO: {
        vehicleNumber: values.vehicleNumber,
        vehicleManufacturer: values.vehicleManufacturer.toUpperCase(),
        vehicleModel: values.vehicleModel.toUpperCase(),
        vehicleOdometerReading: values.vehicleOdometerReading,
        vehicleAverageKmsPerDay: values.vehicleAverageKmsPerDay,
        driverMobileNumber: values.driverMobileNumber,
        vehicleWheels: Number(values.numberOfVehicleWheels),
        vehicleType: "CAR",
      },
      servicesDTO: {
        wheelBalancing: values.services.wheelBalancing.selected,
        weightBalancing: values.services.weightBalancing.selected,
        tyrePuncture: values.services.tyrePuncture.selected,
        others: values.services.others.selected,
        wheelBalancingQuantity:
          values.services.wheelBalancing.wheelBalancingQuantity || 0,
        wheelBalancingPrice:
          values.services.wheelBalancing.wheelBalancingPrice || 0,
        weightBalancingQuantity:
          values.services.weightBalancing.weightBalancingQuantity || 0,
        weightBalancingPrice:
          values.services.weightBalancing.weightBalancingPrice || 0,
        tyrePunctureQuantity:
          values.services.tyrePuncture.tyrePunctureQuantity || 0,
        tyrePuncturePrice: values.services.tyrePuncture.tyrePuncturePrice || 0,
        othersQuantity: values.services.others.othersQuantity || 0,
        othersPrice: values.services.others.othersPrice || 0,
      },
    };

    submitForm(formattedData);
  };

  const submitForm = (formattedData) => {
    setLoading(true);
    invoiceService
      .generateCarInvoice(
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        formattedData
      )
      .then((response) => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          navigate(`/invoice/${response.data.responseBody}`);
          toast.success(`${response.data.responseMessage}`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("generate-invoice", error.response);
      });
  };

  return (
    <div className="whole-container">
      <Flex className="d-flex justify-content-end align-items-center pt-1">
        <Profile />
      </Flex>
      <div className="box-container p-4 d-flex justify-content-start flex-direction-column">
        <div className="row">
          <div className="col">
            <p className="fw-bold fs-4 heading-ff-poppins">Bill New Service</p>
          </div>
          <div>
            <hr className="ms-1 mt-3 me-4" />
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={wheelerBillingValidations}
            onSubmit={handleSubmit}
            validateOnChange={true}
          >
            {(formik) => (
              <Form>
                <div className="row pt-3 pe-4">
                  <div className="col">
                    <label>
                      Customer Name<span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="customerName"
                      placeholder="Enter Customer Name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="customerName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col ps-4">
                    <label>
                      Customer Mobile Number
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="customerMobileNumber"
                      placeholder="Enter Customer Mobile Number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="customerMobileNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col">
                    <label>
                      Vehicle Number<span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="vehicleNumber"
                      placeholder="Enter Vehicle Number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="vehicleNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col ps-4">
                    <label>
                      Vehicle Manufacturer<span className="color-star">*</span>
                    </label>
                    <Field
                      as="select"
                      name="vehicleManufacturer"
                      className="form-select"
                    >
                      <option value="">Select manufacturer</option>
                      <option value="Toyota">Toyota</option>
                      <option value="Honda">Honda</option>
                      <option value="Ford">Ford</option>
                      <option value="BMW">BMW</option>
                      <option value="AUDI">AUDI</option>
                    </Field>
                    <ErrorMessage
                      name="vehicleManufacturer"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col">
                    <label>
                      Vehicle Model<span className="color-star">*</span>
                    </label>
                    <Field
                      as="select"
                      name="vehicleModel"
                      className="form-select"
                    >
                      <option value="">Select model of the Vehicle</option>
                      <option value="Corolla">Corolla</option>
                      <option value="Civic">Civic</option>
                      <option value="Mustang">Mustang</option>
                    </Field>
                    <ErrorMessage
                      name="vehicleModel"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col ps-4">
                    <label>
                      Vehicle Odometer Reading
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      type="number"
                      name="vehicleOdometerReading"
                      placeholder="Enter Vehicle Odometer Reading"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="vehicleOdometerReading"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col">
                    <label>
                      Vehicle Average KM's Per Day
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      type="number"
                      name="vehicleAverageKmsPerDay"
                      placeholder="Enter Vehicle Average KM's Per Day"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="vehicleAverageKmsPerDay"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col ps-4">
                    <label>Created On</label>
                    <Field
                      type="date"
                      name="createdOn"
                      className="form-control"
                      value={currentDate}
                      disabled
                    />
                    <ErrorMessage
                      name="createdOn"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col-md-6">
                    <label>
                      Driver Mobile Number
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      type="text"
                      name="driverMobileNumber"
                      placeholder="Enter Driver Mobile Number"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="driverMobileNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col">
                    <p className="mt-3 fw-bold pb-1">
                      Select the services<span className="color-star">*</span>
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="ms-2"
                        style={{ color: "#cfcfcf" }}
                      />
                    </p>
                    <p>Click all the services performed</p>

                    <div className="col form-check mt-2">
                      <Field
                        type="checkbox"
                        className="form-check-input me-1"
                        name="services.wheelBalancing.selected"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="wheelBalancing"
                      >
                        Wheel Alignment
                      </label>

                      <div className="d-flex input-size gap-3">
                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.wheelBalancing.wheelBalancingQuantity"
                            placeholder="Quantity"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.wheelBalancing.wheelBalancingQuantity"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.wheelBalancing.wheelBalancingPrice"
                            placeholder="Price"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.wheelBalancing.wheelBalancingPrice"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col form-check mt-2">
                      <Field
                        type="checkbox"
                        className="form-check-input me-1"
                        name="services.weightBalancing.selected"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="weightBalancing"
                      >
                        Weight Balancing
                      </label>

                      <div className="d-flex input-size gap-3">
                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.weightBalancing.weightBalancingQuantity"
                            placeholder="Quantity"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.weightBalancing.weightBalancingQuantity"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.weightBalancing.weightBalancingPrice"
                            placeholder="Price"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.weightBalancing.weightBalancingPrice"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col form-check mt-2">
                      <Field
                        type="checkbox"
                        className="form-check-input me-1"
                        name="services.tyrePuncture.selected"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="tyrePuncture"
                      >
                        Tyre Puncture
                      </label>

                      <div className="d-flex input-size gap-3">
                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.tyrePuncture.tyrePunctureQuantity"
                            placeholder="Quantity"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.tyrePuncture.tyrePunctureQuantity"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.tyrePuncture.tyrePuncturePrice"
                            placeholder="Price"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.tyrePuncture.tyrePuncturePrice"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col form-check mt-2">
                      <Field
                        type="checkbox"
                        className="form-check-input me-1"
                        name="services.others.selected"
                      />
                      <label className="form-check-label" htmlFor="others">
                        Others
                      </label>

                      <div className="d-flex input-size gap-3">
                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.others.othersQuantity"
                            placeholder="Quantity"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.others.othersQuantity"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>

                        <div className="w-50">
                          <Field
                            type="number"
                            name="services.others.othersPrice"
                            placeholder="Price"
                            className="form-control mt-1"
                          />
                          <ErrorMessage
                            name="services.others.othersPrice"
                            component="div"
                            className="text-danger mt-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col d-flex align-items-center">
                    <Field
                      type="checkbox"
                      name="useGstInvoice"
                      className="form-check-input me-2"
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor="useGstInvoice"
                    >
                      Use GST Invoice
                    </label>
                  </div>
                </div>

                <div className="row mt-3 pe-4">
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="gstinNumber">
                        GSTIN Number<span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        id="gstinNumber"
                        name="gstinNumber"
                        placeholder="Enter GSTIN Number"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="gstinNumber"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label htmlFor="businessName">
                        Business Name<span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        id="businessName"
                        name="businessName"
                        placeholder="Enter Business Name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="businessName"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>
                </div>

                <div className="col mt-3 mb-4">
                  <button
                    type="submit"
                    className="button-indigo"
                    disabled={loading}
                  >
                    {loading ? "Generating..." : "Generate Invoice"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default WheelerBilling;
