import "../assets/scss/font-size.scss";
import "../assets/scss/font-weight.scss";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SetingIcon from "../assets/images/setting.png";
import LogoutIcon from "../assets/images/logout.png";
import DefaultProfile from "../assets/images/defaultProfile.png";
import DataTransferService from "../core/service/dataTransferService";

const Profile = () => {
  const dataTransferService = new DataTransferService();

  const handleLogout = () => {
    dataTransferService.logout();
    window.location.href = "/login";
  };

  return (
    <div>
      <Dropdown align="end">
        <Dropdown.Toggle
          as="img"
          src={DefaultProfile}
          alt="User profile"
          className="rounded-circle pointer"
          id="dropdown-custom-components"
          width={42}
          height={42}
        />

        <Dropdown.Menu>
          <Dropdown.Item
            as={Link}
            to="/settings"
            className="fs-14 d-flex align-items-center"
          >
            <img
              src={SetingIcon}
              alt="setting-icon"
              width={20}
              height={20}
              className="me-2"
            />
            <p className="mb-0">Settings</p>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="fs-14 d-flex align-items-center"
          >
            <img
              src={LogoutIcon}
              alt="logout-icon"
              width={20}
              height={20}
              className="me-2"
            />
            <p className="mb-0">Logout</p>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Profile;
