import Profile from "../../common/profile";

const EditUser =() =>{
    return(
        <>
        <div className="d-flex justify-content-between align-items-center pt-3">
          <p className="fs-4 fw-500">Settings</p>
          <Profile />
        </div>

        <div className="custom-container mt-36 p-4">
        <p className="fs-4 fw-600">Invite Employees</p>
        </div>
      </>
    )
}

export default EditUser;