import { useNavigate } from "react-router-dom";
import { useState } from "react"; 
import DataTransferService from "../../core/service/dataTransferService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import UserService from "../../core/service/userService";
import Loader from "../../core/loader/loader";

const ReviewOrganisation = () => {
  const userService = new UserService();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dataTransferService = new DataTransferService();

  const handleCreateOrganisation = () => {
    dataTransferService.removeOrganisationDTO();
    navigate("/create-organisation");
  };

  const fetchOrgAndBran = () => {
    setLoading(true);
    userService
      .fetchOrgAndBranName()
      .then((response) => {
        if (response.data.responseCode === 200) {
          if (response.data.responseBody.organisations.length > 0) {
            dataTransferService.removeOrganisationDTO();
            dataTransferService.setOrgAndBranches(
              response.data.responseBody.organisations
            );
            setLoading(false);
            window.location.href = "/";
          } else {
            setLoading(false);
            window.location.href = "/create-organisation";
          }
        }
      })
      .catch((error) => {
        setLoading(false); 
        handleErrorResponse("admin-fetch-org", error.response);
      });
  };

  return (
    <div className="d-flex align-items-center justify-content-center full-height">
      <div className="review-organisation-container p-4">
        <p className="fs-22 fw-600">Organisations</p>
        <div className="pt-40">
          <p className="fw-400 fs-6">Here’s your list of organisations</p>
        </div>

        <div className="d-flex align-items-center pt-3">
          <img
            src={dataTransferService.getBloburl() + dataTransferService.getOrgProfileImage()}
            alt="user-profile-icon"
            className="rounded-circle me-3"
            width={45}
            height={45}
          />
          <div className="d-flex flex-column">
            <span className="fw-600 fs-4">
              {dataTransferService.getOrgName()}
            </span>
            <span className="fw-400 fs-6">
              {dataTransferService.getOrgCount()} locations
            </span>
          </div>
        </div>

        {loading && (
          <div className="text-center mt-4">
           <Loader loading={loading} />
          </div>
        )}

        <div className="pt-40">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline w-100"
            onClick={handleCreateOrganisation}
          >
            + Add Organisation
          </button>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline w-100 mt-4"
            onClick={fetchOrgAndBran}
          >
            Continue to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewOrganisation;
