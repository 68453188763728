import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/scss/font-size.scss";
import "../assets/scss/font-weight.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ReturnIcon from "../assets/images/return.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import DataTransferService from "../core/service/dataTransferService";
import { DropdownComponent } from "../common/dropDown";
import "bootstrap/dist/css/bootstrap.min.css";
import Flex from "../common/flex";

const SideNav = () => {
  const dataTransferService = useMemo(() => new DataTransferService(), []);
  const [orgData, setOrgData] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [branches, setBranches] = useState([]);
  const location = useLocation();
  const hiddenPathsForBilling = [
    "/settings",
    "/manage-organisations",
    "/users",
    "/manage-branches",
    "/manage-users",
    "/edit-user",
  ];
  
  const hiddenPathsForSettings = [
    "/",
    "/service-request",
    "/select-vehicle",
    "/recent-services",
    "/service-alerts",
    "/alerts-history",
    "/wheeler-billing",
    "/heavy-vehicle-billing",
    "/invoice",
    "/show-credit-history",
    "/return-tracker",
    "/new-return-tracker",
  ];

  const shouldHideSettings =
    hiddenPathsForSettings.includes(location.pathname) ||
    location.pathname.startsWith("/alerts-history/") ||
    location.pathname.startsWith("/invoice/");

  const navigate = useNavigate();

  const userRole = dataTransferService.getUserInfo().userRole;
  useEffect(() => {
    const storedOrg = dataTransferService.getSelectedOrganization();
    const storedData = dataTransferService.getOrgAndBranches();
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setOrgData(parsedData);
      const orgNamesString = parsedData
        .map((org) => org.organisationName)
        .join(", ");
      dataTransferService.setListOfOrg(orgNamesString);
      if (storedOrg) {
        const matchedOrg = parsedData.find(
          (org) => org.organisationName === storedOrg
        );
        if (matchedOrg) {
          setSelectedOrg(matchedOrg.organisationName);
          setBranches(matchedOrg.branches);
          dataTransferService.setSelectedOrganization(
            matchedOrg.organisationName
          );
          dataTransferService.setListOfBranches(matchedOrg.branches);
        }
      } else if (parsedData.length > 0) {
        setSelectedOrg(parsedData[0].organisationName);
        setBranches(parsedData[0].branches);
        dataTransferService.setSelectedOrganization(
          parsedData[0].organisationName
        );
        dataTransferService.setListOfBranches(parsedData[0].branches);
      }
    }
  }, [dataTransferService]);

  const handleSelectOrg = (selectedValue) => {
    const org = orgData.find((o) => o.organisationName === selectedValue);
    if (org) {
      setSelectedOrg(org.organisationName);
      setBranches(org.branches);
      dataTransferService.setSelectedOrganization(org.organisationName);
      dataTransferService.setListOfBranches(org.branches);
      const branList = dataTransferService.getListOfBranches();
      if (branList) {
        const parsedBranches = branList.split(",");
        setBranches(parsedBranches);
        if (parsedBranches.length > 0) {
          const initialBranch = parsedBranches[0].trim();
          dataTransferService.setSelectedBranch(initialBranch);
          const currentPath = window.location.pathname;
          window.location.href = currentPath;
        }
      }
    }
  };

  const orgOptions = orgData.map((org, index) => ({
    id: index,
    value: org.organisationName,
    label: org.organisationName,
  }));

  const handleProceed = () => {
    navigate("/");
  };

  return (
    <div className="p-3">
      <Flex className="d-flex align-items-center justify-content-evenly fs-4 fw-semibold pointer mt-4">
        <img
          src={
            dataTransferService.getBloburl() +
            dataTransferService.getOrganisationProfile()
          }
          alt="user-profile-icon"
          className="pe-1"
          width={42}
          height={42}
          onClick={handleProceed}
        />
        <div className="d-flex flex-column">
          <DropdownComponent
            selected={selectedOrg}
            options={orgOptions}
            onSelect={handleSelectOrg}
            toggleClassName="fs-14 fw-500 custom-white-dropdown custom-arrow custom-white-dropdown .dropdown-item"
          />
          <span className="fs-12 fw-normal  grayish-blue">
               {dataTransferService.getSelectedBranch() || "No Branch"}
          </span>
        </div>
      </Flex>

      <div className="mt-3">
        {!hiddenPathsForBilling.includes(location.pathname) && (
          <nav className="navbar">
            <ul>
              <li>
                <>
                  <NavLink to="/" 
                   className={({ isActive }) =>
                    location.pathname === "/" ||
                    location.pathname.startsWith("/select-vehicle") ||
                    location.pathname.startsWith("/recent-services") ||
                    location.pathname.startsWith("/select-vehicle") ||
                    location.pathname.startsWith("/alerts-history") ||
                    location.pathname.startsWith("/wheeler-billing") ||
                    location.pathname.startsWith("/heavy-vehicle-billing")||
                    location.pathname.startsWith("/invoice")||
                    location.pathname.startsWith("/credit-transactions")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  >
                    <div className="px-3 d-flex align-items-center h-46">
                      <img
                        src={ReturnIcon}
                        alt="home-icon"
                        width={24}
                        height={24}
                      />
                      <span className="px-14 pe-16 fs-14 fw-500">Billing</span>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="pointer ms-auto"
                      />
                    </div>
                  </NavLink>
                </>
              </li>
              <li>
                <div className="pt-3">
                  <NavLink to="/return-tracker"
                    className={({ isActive }) =>
                      location.pathname.startsWith("/return-tracker") ||
                    location.pathname.startsWith("/new-return-tracker")
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <div className="px-3 d-flex align-items-center h-46">
                      <img
                        src={ReturnIcon}
                        alt="home-icon"
                        width={24}
                        height={24}
                      />
                      <span className="px-14 pe-16 fs-14 fw-500">
                        Return Tracker
                      </span>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="pointer ms-auto"
                      />
                    </div>
                  </NavLink>
                </div>
              </li>

            </ul>
          </nav>
        )}

        {!shouldHideSettings && (
          <nav className="navbar">
            <ul>
              <li>
                <>
                  <NavLink to="/settings" className="nav-link">
                    <div className="px-3 d-flex align-items-center h-46">
                      <img
                        src={ReturnIcon}
                        alt="home-icon"
                        width={24}
                        height={24}
                      />
                      <span className="px-14 pe-16 fs-14 fw-500">
                        Account information
                      </span>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="pointer ms-auto"
                      />
                    </div>
                  </NavLink>
                </>
              </li>
              {userRole === "ADMIN" && (
                <>
                  <li>
                    <div className="pt-3">
                      <NavLink
                        to="/manage-organisations"
                        className={({ isActive }) =>
                          location.pathname.startsWith(
                            "/manage-organisations"
                          ) || location.pathname.startsWith("/manage-branches")
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        <div className="px-3 d-flex align-items-center h-46">
                          <img
                            src={ReturnIcon}
                            alt="return-icon"
                            width={24}
                            height={24}
                          />
                          <span className="px-14 pe-16 fs-14 fw-500">
                            Manage Organisations
                          </span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="pointer ms-auto"
                          />
                        </div>
                      </NavLink>
                    </div>
                  </li>
                  <li>
                    <div className="pt-3">
                      <NavLink to="/manage-users" className="nav-link">
                        <div className="px-3 d-flex align-items-center h-46">
                          <img
                            src={ReturnIcon}
                            alt="return-icon"
                            width={24}
                            height={24}
                          />
                          <span className="px-14 pe-16 fs-14 fw-500">
                            Manage Users
                          </span>
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="pointer ms-auto"
                          />
                        </div>
                      </NavLink>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default SideNav;
