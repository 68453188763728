import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { DropdownComponent } from "../../common/dropDown";
import { useNavigate } from "react-router-dom";
import Profile from "../../common/profile";
import Loader from "../../core/loader/loader";
import { PaginationComponent } from "../../common/pagination";
import ServiceRequest from "../../core/service/serviceRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { Link } from "react-router-dom";
import Flex from "../../common/flex";
import DataTransferService from "../../core/service/dataTransferService";

const ServiceAlerts = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];

  const [selectedOption, setSelectedOption] = useState("Newest");
  const [searchTerm, setSearchTerm] = useState("");
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState("");
  const dataTransferService = new DataTransferService();
  const serviceRequest = new ServiceRequest();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();

  const fetchService = (page, size, order) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .fetchServices(order, page, size, branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("fetchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const searchService = (page, size) => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .searchServices(page, size, branchName, organisationName, searchTerm)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("searchServices", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      searchService(pageIndex, pageSize);
    } else {
      fetchService(pageIndex, pageSize, selectedOption);
    }
  }, [pageIndex, pageSize, selectedOption, searchTerm]);

  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate("/select-vehicle");
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  return (
    <>
      <Flex className="d-flex align-items-center justify-content-end">
        <Profile />
      </Flex>

      <div className="mt-5 p-4 select-vehicle-container">
        <Flex className="align-items-center justify-content-start">
          <p className="fs-22 fw-600">Service Alerts</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <div className="input-group w-25 pe-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className="input-group-text bg-white border-left-0">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            {searchTerm && (
              <span
                className="input-group-text bg-white border-left-0 pointer"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </div>

          {!searchTerm && (
            <DropdownComponent
              selected={`Sort by: ${selectedOption}`}
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold custom-white-dropdown"
            />
          )}
        </Flex>

        <table className="table mt-5">
          <thead>
            <tr className="fs-14 fw-500 grayish-blue">
              <th>Vehicle Number</th>
              <th>Category</th>
              <th>Mobile Number</th>
              <th>Upcoming Alert</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5">
                  <Loader loading={loading} />
                </td>
              </tr>
            ) : errorMessage ? (
              <tr>
                <td colSpan="5" className="text-center text-danger">
                  {errorMessage}
                </td>
              </tr>
            ) : (
              servicesData.map((service, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={`/alerts-history/${service.invoiceData.vehicleNumber}`}
                      style={{ color: "#127BFF", textDecoration: "none" }}
                    >
                      {service.invoiceData.vehicleNumber}
                    </Link>
                  </td>
                  <td>{service.vehicleType}</td>
                  <td>{service.invoiceData.customerMobileNumber}</td>
                  <td>{service.paymentType || "N/A"}</td>
                  <td>
                    <Link
                      to={`/invoice/${service.invoiceData.invoiceSerialNumber}`}
                      style={{ color: "#127BFF", textDecoration: "none" }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          <span>{`Showing ${pageIndex * pageSize + 1} to ${Math.min(
            (pageIndex + 1) * pageSize,
            totalItems
          )} of ${totalItems} entries`}</span>

          <PaginationComponent
            prevPage={prevPage}
            nextPage={nextPage}
            isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
            isPrevDisabled={pageIndex === 0}
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        </Flex>
      </div>
    </>
  );
};

export default ServiceAlerts;
