import React, { useEffect, useState } from "react";
import Profile from "../../common/profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSearch,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import UserService from "../../core/service/userService";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import Loader from "../../core/loader/loader";
import DataTransferService from "../../core/service/dataTransferService";

const ManageBranches = () => {
  const [branchesData, setBranchesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userService = new UserService();
  const location = useLocation();
  const { organisationName } = location.state || {};
  const [loading, setLoading] = useState(true);
  const goBack = () => {
    window.history.back();
  };
  const dataTransferService = new DataTransferService();

  useEffect(() => {
    if (organisationName) {
      fetchBranches(organisationName);
    }
  }, [organisationName]);

  const fetchBranches = (organisationName) => {
    setLoading(true);
    userService
      .fetchBranches(organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setBranchesData(response.data.responseBody);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("branches-by", error.response);
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");
  };

  const filteredBranches = branchesData.filter(
    (branch) =>
      branch.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branch.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>

      <div className="custom-container mt-36 p-4 ">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={goBack}
            className="pointer"
          />
          <p className="fs-4 fw-600 ps-3 w-75 me-5">
            {organisationName || "Organisation Name"}
          </p>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline ms-4"
          >
            + Add location
          </button>
        </div>

        <div className="pt-4">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by location, name"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className="input-group-text bg-white border-left-0">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            {searchTerm && (
              <span
                className="input-group-text bg-white border-left-0 pointer"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </div>
        </div>

        <div className="pt-40">
          <p className="pt-3 fs-6 fw-600">Your locations</p>
        </div>

        {loading ? (
          <Loader loading={loading} />
        ) : filteredBranches.length > 0 ? (
          filteredBranches.map((branch, index) => (
            <>
              <div
                key={index}
                className="pt-32 d-flex align-items-center justify-content-center"
              >
                <img
                  src={dataTransferService.getBloburl()+ branch.organisationProfile}
                  alt="user-profile-icon"
                  width={80}
                  height={80}
                />
                <div className="d-flex flex-column pe-67 ps-4 w-100">
                  <span className="fs-4 fw-600">{branch.branchName}</span>
                  <span className="fs-15 fw-500">{branch.branchAddress}</span>
                  <span className="fs-14 fw-light">
                    Ph: {branch.branchMobileNumber}
                  </span>
                </div>
                <hr />
              </div>
              <hr />
            </>
          ))
        ) : (
          <p className="d-flex align-items-center justify-content-center">
            No results found
          </p>
        )}
      </div>
    </>
  );
};

export default ManageBranches;
