import { useState, useEffect } from "react";
import Flex from "../../common/flex";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import DataTransferService from "../../core/service/dataTransferService";
import { ServiceRequest } from "../../core/service/serviceRequest";
import { Link } from "react-router-dom"; 
import Loader from "../../core/loader/loader";
import { PaginationComponent } from "../../common/pagination";

const ServiceHistory = ({ vehicleNumber }) => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const serviceRequest = new ServiceRequest();

  useEffect(() => {
    fetchServiceHistory();
  }, [pageIndex]);

  const fetchServiceHistory = () => {
    setLoading(true);
    setErrorMessage("");
    serviceRequest
      .serviceHistory(branchName, organisationName, vehicleNumber, pageIndex, pageSize)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse("servicehistory", error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}

      {!loading && (
        <>
          <Flex className="mt-5 align-items-center justify-content-between header fs-14 fw-500 grayish-blue">
            <span>Invoice Number</span>
            <span>Date</span>
            <span>Payment</span>
            <span>Amount</span>
            <span>Invoice</span>
          </Flex>
          <hr/>

          {servicesData.length === 0 ? (
            <p>No service history available.</p>
          ) : (
            servicesData.map((service, index) => (
              <div key={index}>
                <Flex className="mt-4 align-items-center justify-content-between columns">
                  <p>{service.invoiceData?.invoiceSerialNumber}</p>
                  <p>{formatDate(service.date)}</p>
                  <p>{service.paymentMethod || "N/A"}</p>
                  <p>{`Rs. ${service.invoiceData?.totalAmount}`}</p>
                  <p >
                    <Link to={`/invoice/${service.invoiceData.invoiceSerialNumber}`} style={{ color: "#127BFF", textDecoration: "none" }}>
                      View
                    </Link>
                  </p>
                </Flex>
                <hr />
              </div>
            ))
          )}
        </>
      )}

      <PaginationComponent
        prevPage={() => onPageChange(pageIndex - 1)}
        nextPage={() => onPageChange(pageIndex + 1)}
        isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
        isPrevDisabled={pageIndex === 0}
        pageIndex={pageIndex}
        totalItems={totalItems}
        pageSize={pageSize}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default ServiceHistory;
