import { React, useState, useEffect } from "react";
import { FaCheckCircle, FaTimesCircle, FaClock } from "react-icons/fa";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../../common/flex";
import { Link } from "react-router-dom";
import { DropdownComponent } from "../../common/dropDown";
import Profile from "../../common/profile";
import ReturnTrackerRequest from "../../core/service/returnTrackerRequest";
import { handleErrorResponse } from "../../core/tokenInterceptor/axiosInstance";
import { useNavigate } from "react-router-dom";
import DataTransferService from "../../core/service/dataTransferService";
import { PaginationComponent } from "../../common/pagination";

const ReturnTrackerHome = () => {
  const selectOptions = [
    { id: 1, label: "Newest", value: "Newest" },
    { id: 2, label: "Oldest", value: "Oldest" },
  ];
  const [selectedOption, setSelectedOption] = useState("Newest");
  const returnTrackerRequest = new ReturnTrackerRequest();
  const dataTransferService = new DataTransferService();
  const [counts, setCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);

  const fetchReturnServices = () => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .fetchReturnCountsForBranch(branchName, organisationName)
      .then((response) => {
        if (response.data.responseCode === 200) {
          const { IN_PROCESS, PASSED_QC, FAILED_QC } = response.data.responseBody;
          setCounts({ IN_PROCESS, PASSED_QC, FAILED_QC });
        }
      })
      .catch((error) => {
        setLoading(false);
        handleErrorResponse(error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  const fetchReturnRequests = (serialNumber,page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .searchReturnsBySerialNumber(organisationName, branchName,serialNumber, page, size)
      .then((response) => {
        if (response.data.responseCode === 200) {
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        handleErrorResponse(error.response);
        setErrorMessage(error.response.data.responseMessage);
      })
  };

  const fetchSortedReturns = (order, selectOptions, page, size) => {
    setLoading(true);
    setErrorMessage("");
    returnTrackerRequest
      .sortReturnRequests(
        order,
        organisationName,
        branchName,
        page,
        size,
        selectOptions
      )
      .then((response) => {
        if (response.data.responseCode === 200) {
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
        }
      })
      .catch((error) => {
        handleErrorResponse(error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  };

  useEffect(() => {
    if (searchTerm) {
      fetchReturnRequests(searchTerm,pageIndex, pageSize);
    } else {
      fetchReturnServices();
    }
    fetchSortedReturns(selectedOption, selectOptions, pageIndex, pageSize);
  }, [searchTerm,pageIndex, selectedOption]);

  const handleChangeOptions = (option) => {
    setSelectedOption(option);
    setPageIndex(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm("");

  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const navigate = useNavigate();

  const handlenavigate = () => {
    navigate("/new-return-tracker");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  return (
    <div className="background-container mb-34">
      <div className="d-flex justify-content-end">
        <Profile />
      </div>
      <div className="count-container d-flex justify-content-evenly align-items-center mr-55 mt-38">
        <div className="text-center d-flex  count-containers ml-100 mr-146">
          <div className="process-icon">
            <FaClock className="clock-icon fs-2" />
          </div>
          <div className="ms-3">
            <p className="count-head fs-14">In Process</p>
            <p className="fw-bold count-number-style">{counts.IN_PROCESS}</p>
          </div>
        </div>

        <div className="text-center d-flex count-containers mr-146">
          <div className="passed-icon">
            <FaCheckCircle className="tick-icon fs-2" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Passed QC</p>
            <p className="fw-bold count-number-style">{counts.PASSED_QC}</p>
          </div>
        </div>

        <div className="text-center d-flex count-containers mr-57">
          <div className="failed-icon">
            <FaTimesCircle className="fs-2 wrong-icon" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Failed QC</p>
            <p className="fw-bold count-number-style">{counts.FAILED_QC}</p>
          </div>
        </div>
      </div>

      <div className="card-all-return-requests p-4 mb-34">
        <p className="all-return-requests-head ms-3">All Return Requests</p>
        <Flex className="mt-4 mb-3 align-items-center justify-content-end">
          <div className="input-group w-25 pe-5">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <span className="input-group-text bg-white border-left-0">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            {searchTerm && (
              <span
                className="input-group-text bg-white border-left-0 pointer"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
            )}
          </div>

          {!searchTerm && (
            <DropdownComponent
              selected={
                <>
                  <span className="fs-12 fw-400 grayish-blue">Sort by: </span>
                  {selectedOption}
                </>
              }
              options={selectOptions}
              onSelect={handleChangeOptions}
              toggleClassName="fs-14 fw-semibold custom-white-dropdown"
            />
          )}
        </Flex>
        <Flex className="align-items-center justify-content-end ">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline mb-4 mt-3"
            onClick={handlenavigate}
          >
            + Create a new Return Tracker
          </button>
        </Flex>

        <Table>
          <thead>
            <tr>
              <th className="fw-500 table-headings">Serial Number</th>
              <th className="fw-500 table-headings">Company</th>
              <th className="fw-500 table-headings">Model Name</th>
              <th className="fw-500 table-headings">Customer name</th>
              <th className="fw-500 table-headings">Created</th>
              <th className="fw-500 table-headings">Status</th>
              <th className="fw-500 table-headings">Edit</th>
            </tr>
          </thead>
          <tbody>
            {returnRequests.map((request, index) => (
              <tr key={index}>
                <td className="p-3">{request.serialNumber}</td>
                <td className="p-3"> {request.company}</td>
                <td className="p-3 limited-text">{request.modelName}</td>
                <td className="p-3 limited-text">{request.customerName}</td>
                <td className="p-3">{formatDate(request.created)}</td>
                <td className="p-3">
                  <span
                    className={`status-badge ${
                      request.returnStatus === "IN_PROCESS"
                        ? "status-in-process"
                        : request.returnStatus === "FAILED_QC"
                        ? "status-failed"
                        : "status-passed"
                    }`}
                  >
                    {request.returnStatus}
                  </span>
                </td>
                <td className="p-3">
                  <Link style={{ color: "#127BFF", textDecoration: "none" }}>
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="pt-3">
          <Flex className="mt-4 justify-content-between fs-14 fw-500 grayish-blue">
            <span className="pe-2 table-headings">
              {`Showing ${pageIndex * pageSize + 1} to ${Math.min(
                (pageIndex + 1) * pageSize,
                totalItems
              )} of ${totalItems} entries`}{" "}
              <Link
                className="ps-1"
                style={{ color: "#127BFF", textDecoration: "none" }}
              >
                View all
              </Link>
            </span>{" "}
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          </Flex>
        </div>
      </div>
    </div>
  );
};
export default ReturnTrackerHome;
