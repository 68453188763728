export default class DataTransferService {
  setUuid(token) {
    return localStorage.setItem("Uuid", token);
  }

  logout() {
    return localStorage.clear();
  }

  getUuid() {
    return localStorage.getItem("Uuid");
  }

  setOrgAndBranches(data) {
    return localStorage.setItem("orgAndBran", JSON.stringify(data));
  }

  getOrgAndBranches() {
    return localStorage.getItem("orgAndBran") || null;
  }

  setSelectedOrganization(org) {
    return localStorage.setItem("selectedOrg", org);
  }

  getSelectedOrganization() {
    return localStorage.getItem("selectedOrg");
  }

  setListOfBranches(bran) {
    return localStorage.setItem("listOfBranches", bran);
  }

  getListOfBranches() {
    return localStorage.getItem("listOfBranches");
  }

  setSelectedBranch(bran) {
    return localStorage.setItem("selectedBranch", bran);
  }

  getSelectedBranch() {
    return localStorage.getItem("selectedBranch");
  }

  setListOfOrg(org) {
    return localStorage.setItem("listOfOrg", org);
  }

  getListOfOrg() {
    return localStorage.getItem("listOfOrg");
  }

  setUserInfo(userEmail, userName, userRole) {
    const userInfo = {
      userEmail: userEmail,
      userName: userName,
      userRole: userRole,
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  getUserInfo() {
    const userInfo = localStorage.getItem("userInfo");
    return userInfo ? JSON.parse(userInfo) : null;
  }

  getBloburl() {
    return "https://retailersdev.blob.core.windows.net/images/";
  }

  getOrgProfileImage() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.organisationProfile || null;
    }
    return null;
  }

  getOrgName() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.organisationName || null;
    }
    return null;
  }

  getOrgCount() {
    const organisationDTOString = localStorage.getItem("organisationDTO");
    if (organisationDTOString) {
      const organisationDTO = JSON.parse(organisationDTOString);
      return organisationDTO.numLocations || null;
    }
    return null;
  }

  getUserName() {
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      return userInfo.userName || null;
    }
    return null;
  }

  getUserRole() {
    const userInfoString = localStorage.getItem("userInfo");
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      return userInfo.userRole || null;
    }
    return null;
  }

  removeOrganisationDTO() {
    localStorage.removeItem("organisationDTO");
  }

  getOrganisationDTO() {
    return localStorage.getItem("organisationDTO") || null;
  }
  
  getOrganisationProfile() {
    const orgAndBranString = this.getOrgAndBranches();
    if (orgAndBranString) {
      const orgAndBran = JSON.parse(orgAndBranString);
      const orgData = orgAndBran.find(
        (org) =>
          org.organisationName === this.getSelectedOrganization() &&
          org.branches.includes(this.getSelectedBranch())
      );

      return orgData ? orgData.organisationProfile : null;
    }

    return null;
  }
}
