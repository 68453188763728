import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/login";
import AuthGuard from '../core/guard/authguard';
import LoginGuard from '../core/guard/loginGuard';
import Settings from "../components/settings";
import Invoice from "../components/invoice";
import InviteUser from "../components/setup-organisation/inviteUsers";
import ManageOrganisation from "../components/admin-access/manageOrganisation";
import ManageBranches from "../components/admin-access/manageBranches";
import ManageUsers from "../components/admin-access/manageUsers";
import Billing from "../components/billing";
import SelectVehicle from "../components/service-request/selectVehicle";
import ReviewOrganisation from "../components/setup-organisation/reviewOrganisation";
import Organisation from "../components/setup-organisation/organisation";
import ViewBranches from "../components/setup-organisation/viewBranches";
import CreateBranches from "../components/setup-organisation/createBranches";
import InviteUsers from "../components/setup-organisation/inviteUsers";
import InviteForm from "../components/setup-organisation/inviteForm";
import RecentServices from "../components/service-request/recentService";
import ServiceAlerts from "../components/service-request/serviceAlerts";
import ServiceHistory from "../components/service-request/serviceHistory";
import UpComingAlerts from "../components/service-request/upComingAlerts";
import AlertsAndHistory from "../components/service-request";
import EditUser from "../components/admin-access/editUser";
import SetPassword from "../components/setPassword";
import WheelerBilling from "../components/service-request/wheelerBilling";
import HeavyVehicleBilling from "../components/service-request/heavyVehicleBilling";
import NotFound from "../components/pageNotFound";
import ReturnTrackerHome from "../components/return-tracker/returnTrackerHome";
import ShowCreditHistory from "../components/showCreditHistory";
import ForgotPassword from "../components/forgotPassword";
import NewReturnTracker from "../components/return-tracker/newReturnTracker";

const RetailersRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginGuard element={Login} />} />
      
      <Route path="/settings" element={<AuthGuard element={Settings} />} />
      <Route path="/edit-user" element={<AuthGuard element={EditUser} />} />
      <Route path="/" element={<AuthGuard element={Billing} />} />
      <Route path="/manage-organisations" element={<AuthGuard element={ManageOrganisation} />} />
      <Route path="/manage-users" element={<AuthGuard element={ManageUsers} />} />
      <Route path="/select-vehicle" element={<AuthGuard element={SelectVehicle} />} />
      <Route path="/manage-branches" element={<AuthGuard element={ManageBranches} />} />
      <Route path="/create-organisation" element={<AuthGuard element={Organisation} />} />
      <Route path="/review-organisation" element={<AuthGuard element={ReviewOrganisation} />} />
      <Route path="/view-branches" element={<AuthGuard element={ViewBranches} />} />
      <Route path="/create-branches" element={<AuthGuard element={CreateBranches} />} />
      <Route path="/invite-users" element={<AuthGuard element={InviteUsers} />} />
      <Route path="/invite-form" element={<AuthGuard element={InviteForm} />} />
      <Route path="/recent-services" element={<AuthGuard element={RecentServices} />} />
      <Route path="/service-alerts" element={<AuthGuard element={ServiceAlerts} />} />
      <Route path="/service-history" element={<AuthGuard element={ServiceHistory} />} />
      <Route path="/upcoming-alerts" element={<AuthGuard element={UpComingAlerts} />} />
      <Route path="/alerts-history/:vehicleNumber" element={<AuthGuard element={AlertsAndHistory} />} />
      <Route path="/set-password" element={<SetPassword/>} />
      <Route path="/wheeler-billing" element={<AuthGuard element={WheelerBilling} />} />
      <Route path="/create-organistion" element={<AuthGuard element={Organisation} />} />
      <Route path="/manage-users" element={<AuthGuard element={ManageUsers} />} />
      <Route path="/invite-users" element={<AuthGuard element={InviteUser} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/organisation" element={<Organisation/>}/>
      <Route path="/login" element={<Login />} />
      <Route path="/organisation" element={<Organisation/>}/>
      <Route path="/invoice/:invoiceSerialNumber" element={<AuthGuard element={Invoice} />}/>
      <Route path="/heavy-vehicle-billing" element={<AuthGuard element={HeavyVehicleBilling} />} />
      <Route path="/forgot-password" element={<ForgotPassword/>} />
      <Route path="errors/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
      <Route path="/return-tracker" element={<ReturnTrackerHome/>}/>
      <Route path="/show-credit-history" element={<ShowCreditHistory/>}/>
      <Route path="/new-return-tracker" element={<NewReturnTracker/>}/>
    </Routes>
  );
};
export default RetailersRoutes;
