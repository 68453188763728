import React, { useEffect, useState } from "react";
import Profile from "../common/profile";
import "../assets/scss/font-size.scss";
import "../assets/scss/font-weight.scss";
import DataTransferService from "../core/service/dataTransferService";

const dataTransferService = new DataTransferService();

const Settings = () => {
  const [userInfo, setUserInfo] = useState({
    username: "",
    userEmail: "",
    userRole: "",
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userData = await dataTransferService.getUserInfo();
        setUserInfo({
          username: userData.userName || "",  
          userEmail: userData.userEmail || "", 
          userRole: userData.userRole || "",   
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, []); 

  const handleNameChange = (e) => {
    setUserInfo({ ...userInfo, username: e.target.value });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>
      <div className="custom-container mt-36 p-4">
        <p className="fs-4 fw-600">Account Settings</p>
        
        <label className="fs-14 fw-500 pt-4">
          Name <span className="color-star">*</span>
        </label>
        <input
          className="form-control custom-input"
          placeholder="Jhon"
          value={userInfo.username}
          onChange={handleNameChange}
        />
        
        <label className="fs-14 fw-500 pt-4">
          Email <span className="color-star">*</span>
        </label>
        <input
          className="form-control custom-input"
          type="email"
          placeholder="jhom@mail.com"
          value={userInfo.userEmail}
          disabled
        />
        
        <label className="fs-14 fw-500 pt-4">
          Role <span className="color-star">*</span>
        </label>
        <input
          className="form-control custom-input"
          placeholder="Admin"
          value={userInfo.userRole}
          disabled
        />
      </div>
    </>
  );
};

export default Settings;
